<template>
	<div class="">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>账号设置</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-form ref="form" label-width="80px">
				<el-form-item label="账号">
					<el-input v-model="account" disabled></el-input>
				</el-form-item>
				<el-form-item label="新密码">
					<el-input v-model="newPassword" placeholder="请输入新密码" show-password></el-input>
				</el-form-item>
				<el-form-item label="确认密码">
					<el-input v-model="passwordCopy" placeholder="请确认密码" show-password></el-input>
				</el-form-item>
				<el-form-item>
					<div class="point">密码需包含大写字母、小写字母、数字、特殊符号中任意三种内容</div>
				</el-form-item>
<!-- 				<el-form-item label="确认密码">
					<div class="images">
						{{url?"":"暂无头像"}}
					</div>
				</el-form-item> -->
				<el-form-item>
					<div class="btns">
						<el-button type="primary" @click="sub">保存</el-button>
					</div>
				</el-form-item>
				
			</el-form>
		</el-card>
	</div>
</template>
<script>
	import {
		reset_password
	} from '../api/account'
	import {
		bian_log
	} from '../api/log'
	export default {
		data() {
			return {
				url:"",
				account: '',
				newPassword: '',
				passwordCopy: '',
			};
		},
		computed: {},
		methods: {
			sub() {
				if (this.newPassword == '') {
					return this.$message.error('请输入新密码')
				}
				if (this.passwordCopy == '') {
					return this.$message.error('请确认密码')
				}
				if (this.passwordCopy != this.newPassword) {
					return this.$message.error('两次密码输入不一致，请重新输入')
				}
				// 正则
				//var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])');
				let ruleNum = 0;
				const regexA = new RegExp('(?=.*[0-9])')
				if (regexA.test(this.passwordCopy)) {
					ruleNum += 1;
				}
				const regexB = new RegExp('(?=.*[A-Z])')
				if (regexB.test(this.passwordCopy)) {
					ruleNum += 1;
				}
				const regexC = new RegExp('(?=.*[a-z])')
				if (regexC.test(this.passwordCopy)) {
					ruleNum += 1;
				}
				const regexD = new RegExp('(?=.*[^a-zA-Z0-9])')
				if (regexD.test(this.passwordCopy)) {
					ruleNum += 1;
				}
				if (ruleNum < 3) {
					return this.$message.error('密码需包含大写字母、小写字母、数字、特殊符号中任意三种内容');
				}
				reset_password(this.adminId, this.account, this.newPassword).then(res => {
					if (res.status == 200) {
						this.$message.success('设置成功')
						bian_log(`修改${this.account}密码`)
					}
				})
			}
		},
		created() {
			this.account = sessionStorage.getItem('account');
			this.adminId = sessionStorage.getItem('admin-id');
		},
	};
</script>
<style lang='less' scoped>
	.point {
		font-size: 14px;
		color: #999;
	}

	.btns {
		display: flex;
		justify-content: center;
	}
	.images{
		text-align: center;
		line-height: 200px;
		width: 200px;
		height: 200px;
	}
</style>
