import request from '../utils/request'

export const reset_password=(id,account,new_password)=>{
    return request({
        url:'/admin/reset_password',
        method:'post',
        data:{
            id,
            account,
            new_password
        }
    })
}